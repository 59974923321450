import { ReactElement } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useTranslation } from 'react-i18next';

interface ContinueInBottomSheetProps {
    open: boolean;
    onDismiss: () => void;
    appLink: string;
}

export default function ContinueInBottomSheet({
    open,
    onDismiss,
    appLink
}: ContinueInBottomSheetProps): ReactElement {
    const { t } = useTranslation();

    const header = <div className="d-flex justify-content-center fs-6" style={{
        fontWeight: "bold",
        fontFamily: "Roboto"
    }}>{t("translation.more_convenient_msg")}</div>;

    return (
        <BottomSheet open={open} header={header} onDismiss={onDismiss}>
            <div>
                <div style={{"marginBottom": "20px", marginTop: "10px"}}>
                    <div className="d-grid" style={{"paddingLeft": "10px", "paddingRight": "10px"}}>
                        <div className="row" style={{"marginBottom": "15px"}}>
                            <div className="col-8">
                                <div className="fs-5">
                                    Life Deals Club App
                                </div>
                            </div>
                            <div className="col-4">
                                <a href={appLink} target="_blank" rel="noreferrer noopener">
                                    <button className="btn btn-primary w-100 p-1" style={{"borderRadius": "15px"}}>
                                        {t("translation.open_btn")}
                                    </button>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-8">
                                <div className="fs-5">
                                    {t("translation.browser")}
                                </div>
                            </div>
                            <div className="col-4">
                                <button className="btn btn-secondary w-100 p-1" style={{"borderRadius": "15px"}}
                                        onClick={onDismiss}>
                                    {t("translation.continue_btn")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </BottomSheet>
    );
}
