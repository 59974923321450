import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownButton from 'react-bootstrap/DropdownButton';

interface ILanguageSwitchProps {
  className?: string;
  initLangCode?: string;
  handleLanguageChange: Function;
  variant?: string;
}

type Languages = {
  [langCode: string]: string;
};

const langs: Languages = {
  en: 'English',
  tr: 'Türkçe',
  el: 'Ελληνικά',
};

const LanguageSwitch = ({
  className,
  initLangCode,
  variant = 'outline-dark',
  handleLanguageChange,
}: ILanguageSwitchProps) => {
  const dispLang = langs[initLangCode?.toLowerCase() ?? 'en'];

  return (
    <DropdownButton
      title={dispLang}
      id="langDropdownMenuButton"
      className={`${className} dropdown`}
      variant={variant}
      size="sm"
    >
      {Object.entries(langs).map(([langCode, lang]) => (
        <DropdownItem
          key={langCode}
          className="dropdown-item"
          onClick={() => {
            handleLanguageChange(langCode);
          }}
        >
          {lang}
        </DropdownItem>
      ))}
    </DropdownButton>
  );
};

export default LanguageSwitch;
