import { Field, Form, Formik, FormikHelpers } from 'formik';
import { FC, useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import html2canvas from 'html2canvas';
import download from 'downloadjs';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from './LanguageSwitch';
import 'react-spring-bottom-sheet/dist/style.css';
import ContinueInBottomSheet from './ContinueInBottomSheet';
import DownloadAppPrompt from './Download';

export interface Guest {
  name: string;
  surname: string;
  gender: string;
  phoneNumber: string;
  email: string;
}

export const fetchWrapper = async (url: string, options: RequestInit) => {
  const baseUrl = process.env.REACT_APP_LDC_API_BASEURL ?? '';
  return fetch(baseUrl + url, options);
};

export interface PromotionDetails {
  promotionId: string;
  promotionName: string;
  imageId: string | null;
  defaultImageId: string;
  shopName: string;
  hasInstagramDiscount: boolean;
  isValid: string;
  expiryDate: string;
  source: string;
}

export interface storeDynamicLinkResponse {
  storeDynamicLink: string;
}

const phoneRegExp = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;

const promotionApiKey: string = process.env.REACT_APP_PROMOTIONS_APIKEY!;

export const Dataform: FC = () => {
  let [pUsageId, setPUsageId] = useState('');
  let [storeLink, setStoreLink] = useState('');

  let [promoInfo, setPromoInfo] = useState<PromotionDetails>({
    promotionId: '',
    promotionName: '',
    expiryDate: '',
    imageId: '',
    defaultImageId: '',
    isValid: '',
    shopName: '',
    source: '',
    hasInstagramDiscount: false,
  });
  let [storeDynamicLink, setStoreDynamicLink] = useState({
    storeDynamicLink: '',
  });
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { id, langCode } = useParams<{ id?: string; langCode?: string }>();
  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const { t, i18n } = useTranslation();

  const GuestSchema = Yup.object().shape({
    name: Yup.string().required(t('translation.validation_required')),
    surname: Yup.string().required(t('translation.validation_required')),
    gender: Yup.string()
      .required(t('translation.validation_required'))
      .matches(/^[FMN]$/, t('translation.validation_gender_msg')),
    phoneNumber: Yup.string()
      .required(t('translation.validation_required'))
      .matches(phoneRegExp, t('translation.validation_phone_number')),
    email: Yup.string()
      .required(t('translation.validation_required'))
      .email(t('translation.validation_phone_number')),
  });

  const InstagramMessage: FC = () => {
    return (
      <div>
        <p className="mb-4 text-darker" style={{ fontSize: '16px' }}>
          {t('translation.instagram_msg_1')} <br />
          {t('translation.instagram_msg_2')}
        </p>
      </div>
    );
  };

  async function fetchPromotionAsync() {
    var langParam;
    const lang = localStorage.getItem('lang_code');
    if (lang && lang === langCode) {
      langParam = lang;
    } else {
      langParam = langCode;
    }
    let response = await fetchWrapper(
      '/api/guest/' + id + '/' + langParam + '?isEggEnabled=true',
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          apiKey: promotionApiKey,
        },
      }
    );
    if (response.status > 200) {
      alert(t('translation.promotion_not_found'));
      window.location.href = 'https://' + window.location.host;
    } else {
      await response.json().then((data) => {
        const fetchedData = data as PromotionDetails;
        setPromoInfo(fetchedData);
        setLoading(false);
      });
    }
  }

  async function fetchPromotionDeepLink() {
    let response = await fetchWrapper('/api/promotionsource/' + id, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', apiKey: promotionApiKey },
    });
    if (response.status > 200) {
      alert(t('translation.promotion_not_found'));
      window.location.href = 'https://' + window.location.host;
    } else {
      await response.json().then((data) => {
        const fetchedData = data as storeDynamicLinkResponse;
        storeDynamicLink = fetchedData;
        setStoreDynamicLink(fetchedData);
      });
    }
  }

  async function putScannedPromotionAsync() {
    var reloadedPage = localStorage.getItem('reloaded_page');
    if (!reloadedPage) {
      await fetchWrapper('/api/guest', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          apiKey: promotionApiKey,
        },
        body: JSON.stringify({
          promotionSourceId: id,
          userId: null,
        }),
      });
    }
  }

  async function initialize() {
    // reload page with appropriate locale
    reloadPageWithBrowserLocale();
    changeLanguageFromLocal();
    //
    if (isAndroid) {
      setStoreLink(
        'https://play.google.com/store/apps/details?id=com.qr_code_app'
      );
    } else if (isIOS) {
      setStoreLink(
        'https://apps.apple.com/tr/app/purple-perks-club/id1613341800'
      );
    } else {
      setStoreLink('#');
    }
    await fetchPromotionDeepLink();
    fetchPromotionAsync()
      .then(async () => {
        await putScannedPromotionAsync();
        handleBottomSheet();
      })
      .catch((err) => console.log(err.message));
  }

  useEffect(() => {
    initialize();
  }, []);

  function handleBottomSheet() {
    setShowBottomSheet(true);
  }

  function onDismiss() {
    setShowBottomSheet(false);
  }

  function renderBottomSheet() {
    const appLink = storeDynamicLink.storeDynamicLink;
    return (
      <ContinueInBottomSheet
        open={showBottomSheet}
        onDismiss={onDismiss}
        appLink={appLink}
      />
    );
  }

  function reloadPageWithBrowserLocale() {
    var localLangCode = localStorage.getItem('lang_code');
    if (!localLangCode) {
      var browserLangCode = window.navigator.language.trim();
      browserLangCode = browserLangCode.split(/-|_/)[0];
      if (browserLangCode !== langCode?.toLowerCase()) {
        const url =
          'https://' +
          window.location.host +
          window.location.pathname.replace(langCode!, browserLangCode);
        localStorage.removeItem('lang_code');
        window.location.href = url;
      }
    }
  }

  function changeLanguageFromLocal() {
    const lang = localStorage.getItem('lang_code');
    var langToChange: string | null = null;
    if (!lang) {
      langToChange = langCode!;
      var browserLangCode = window.navigator.language.trim();
      browserLangCode = browserLangCode.split(/-|_/)[0];
      localStorage.setItem('lang_code', browserLangCode);
    }
    i18n.changeLanguage(langToChange ?? langCode);
  }

  async function SubmitForm(values: any) {
    setLoading(true);
    let response = await fetchWrapper('/api/guest/' + id, {
      method: 'POST',
      body: JSON.stringify(values),
      headers: { 'Content-Type': 'application/json', apiKey: promotionApiKey },
    });
    if (response.status === 400) {
      alert('REGISTER FAILED');
      setLoading(false);
    } else {
      await response.json().then((data) => {
        pUsageId = data.value;
        setPUsageId(data.value);
      });
      showDownloadPage();
      setLoading(false);
    }
  }

  async function downloadQR() {
    var _canvas = document.getElementById(
      'qrImageWithText'
    ) as HTMLCanvasElement;
    html2canvas(_canvas).then(function (canvas) {
      var image = canvas
        .toDataURL('image/png')
        .replace('image/png', 'image/octet-stream');
      var fileName =
        promoInfo.shopName +
        '_' +
        promoInfo.promotionName +
        '_' +
        t('translation.qr_code') +
        '.png';
      download(image, fileName, 'image/png');
      _canvas = canvas;
      console.log('download done');
    });
  }

  function showDownloadPage() {
    //TODO: Call the create promotionView endpoint to create a promotionView for this guest
    setIsSubmitted(true);
  }

  function changeLanguage(languageCode: string) {
    // i18n.changeLanguage(languageCode);
    localStorage.setItem('lang_code', languageCode);
    window.location.href =
      'https://' +
      window.location.host +
      window.location.pathname.replace(langCode!, languageCode);
  }

  function getLanguage() {
    const currentLang = i18n.language;
    return currentLang === 'en'
      ? 'en-GB'
      : currentLang === 'tr'
      ? 'tr-TR'
      : currentLang === 'el'
      ? 'el-CY'
      : 'en-GB';
  }

  function ErrorLabel({ error }: { error: string }) {
    return <span className="Error">{error}</span>;
  }

  const spinner: JSX.Element = (
    <div>
      <ClipLoader color={'#00ffcc'} />
    </div>
  );
  const urlPrefix = 'https://' + window.location.host;
  const imgUrlPrefix = urlPrefix + '/api/promotions/image/';
  const defaultImageUrlPrefix = urlPrefix + '/api/shared-image/';
  const imageUrl = !!promoInfo.imageId
    ? imgUrlPrefix + promoInfo.imageId
    : defaultImageUrlPrefix + promoInfo.defaultImageId;

  const form: JSX.Element = (
    <Formik
      initialValues={{
        name: '',
        surname: '',
        gender: 'M',
        email: '',
        phoneNumber: '',
      }}
      onSubmit={async (
        values: Guest,
        { setSubmitting }: FormikHelpers<Guest>
      ) => {
        await SubmitForm(values);
        showDownloadPage();
      }}
      validationSchema={GuestSchema}
    >
      {({ errors, touched }) => (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
          <div
            style={{ height: 'fit-content' }}
            className="border-md card-md shadow-md-sm m-md-auto"
          >
            <div className=" h-[71px]">
              <DownloadAppPrompt storeDynamicLink={storeDynamicLink} />
            </div>
            <div
              className="py-3"
              style={{
                display: 'flex',
                justifyContent: 'end',

                paddingLeft: '3%',
                paddingRight: '3%',
                paddingTop: '2%',
              }}
            >
              <LanguageSwitch
                initLangCode={langCode}
                handleLanguageChange={changeLanguage}
              />
            </div>

            <hr className="Divider" />
            <div className=" GridContainer grid  grid-flow-col ">
              <div className="PromotionDetails  ">
                <p className="fs-5">
                  {promoInfo.promotionName}
                  <br />
                  <br />- {promoInfo.shopName}
                </p>
              </div>
              <img className="DataFormImage" src={imageUrl} alt="Promotion" />
            </div>
            {promoInfo.hasInstagramDiscount ? <InstagramMessage /> : null}
            <hr className="Divider" />
            <div
              style={{ maxWidth: '500px', margin: 'auto' }}
              className="p-3 py-4"
            >
              <p
                className="h6 text-darker  "
                style={{
                  fontSize: '16px',
                  fontWeight: 'bold',
                }}
              >
                {t('translation.fill_details_msg')}
              </p>
              <Form className="DataForm my-4 ">
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '49% 49%',
                    gap: '2%',
                  }}
                >
                  <div className="FormField">
                    <label htmlFor="name" className="FormFieldLabel">
                      {t('translation.name_title')}
                    </label>
                    <Field id="name" name="name" className="Input fs-6" />
                    {errors.name && touched.name ? (
                      <div className="Error">{errors.name}</div>
                    ) : null}
                  </div>

                  <div className="FormField">
                    <label htmlFor="surname" className="FormFieldLabel">
                      {t('translation.surname_title')}{' '}
                    </label>
                    <Field id="surname" name="surname" className="Input fs-6" />
                    {errors.surname && touched.surname ? (
                      <div className="Error">{errors.surname}</div>
                    ) : null}
                  </div>
                </div>

                <div className="FormField">
                  <label htmlFor="phoneNumber" className="FormFieldLabel">
                    {t('translation.phone_number')}
                  </label>
                  <Field
                    id="phoneNumber"
                    name="phoneNumber"
                    className="Input fs-6"
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <div className="Error">{errors.phoneNumber}</div>
                  ) : null}
                </div>

                <div className="FormField">
                  <label htmlFor="email" className="FormFieldLabel">
                    {t('translation.email_address')}
                  </label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    className="Input fs-6"
                  />
                  {errors.email && touched.email ? (
                    <div className="Error">{errors.email}</div>
                  ) : null}
                </div>

                <div className="FormField">
                  <label htmlFor="gender" className="FormFieldLabel">
                    {t('translation.gender_title')}
                  </label>
                  <Field
                    id="gender"
                    name="gender"
                    as="select"
                    className="Input fs-6 p-2"
                  >
                    <option value="M">{t('translation.gender_male')}</option>
                    <option value="F">{t('translation.gender_female')}</option>
                    <option value="N">{t('translation.gender_NB')}</option>
                    <option value="P">
                      {t('translation.gender_prefer_not_to_say')}
                    </option>
                  </Field>
                  {errors.gender && touched.gender ? (
                    <div className="Error">{errors.gender}</div>
                  ) : null}
                </div>

                <button type="submit" className="SubmitButton btn">
                  {t('translation.submit_btn')}
                </button>
              </Form>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );

  const downloadQRPage: JSX.Element = (
    <>
      <nav className="navbar navbar-light bg-light mb-4 w-100">
        <div className="navbar-brand p-2 fs-4">{t('translation.qr_code')}</div>
        <div className="OpenAppContainer">
          <div>
            <button className="OpenAppButton btn" onClick={handleBottomSheet}>
              {t('translation.use_app_msg')}
            </button>
          </div>
        </div>
      </nav>
      <div className="QRImageWithText d-grid p-1" id="qrImageWithText">
        <div className="p-2 text-dark fs-4"> {promoInfo.shopName}</div>
        <div className="p-2 text-dark fs-4">{promoInfo.promotionName}</div>
        <div className="d-flex justify-content-center">
          <QRCodeCanvas
            id="qrImage"
            value={'WEB=P=' + pUsageId}
            size={300}
            level="L"
            includeMargin={true}
            className="QRImage border border-dark"
          />
        </div>
      </div>

      <button
        className="btn btn-light btn-outline-dark"
        onClick={() => downloadQR()}
      >
        <i className="bi bi-download" /> {t('translation.save_qr_code')}
      </button>

      <div className="w-100 mt-4">
        <div className="container" style={{ paddingRight: '2rem' }}>
          <div className="d-flex justify-content-center">
            <img className="DownloadImage" src={imageUrl} alt="Promotion" />
          </div>
          <div className="DownloadPromoInfo text-dark fs-6">
            <p className="pt-4" style={{ color: 'red', fontWeight: 'bold' }}>
              {t('translation.expires')} :
              {loading
                ? ''
                : new Intl.DateTimeFormat([getLanguage()], {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                  }).format(new Date(promoInfo.expiryDate))}
            </p>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className="DataForm bg-gradient bg-opacity-50 w-100">
      {loading ? spinner : !isSubmitted ? form : downloadQRPage}
      {renderBottomSheet()}
    </div>
  );
};
