import { ReactElement, useEffect, useRef, useState } from 'react';
import { Pagination, Autoplay } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { Swiper, SwiperRef, SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../Components/LanguageSwitch';
import { isAndroid, isIOS } from 'react-device-detect';

const iOSUrl = 'https://apps.apple.com/tr/app/purple-perks-club/id1613341800';
const androidURL =
  'https://play.google.com/store/apps/details?id=com.qr_code_app';
const privacyPolicyURL =
  'https://' + window.location.host + '/guest/privacypolicy';

const appFeatures = [
  {
    title: 'translation.landing_page_promotions_title',
    desc: 'translation.landing_page_promotions_desc',
    image: '/images/landing_promotions.png',
  },
  {
    title: 'translation.landing_page_giftcards_title',
    desc: 'translation.landing_page_giftcards_desc',
    image: '/images/landing_gift_cards.png',
  },
  {
    title: 'translation.landing_page_qr_title',
    desc: 'translation.landing_page_qr_desc',
    image: '/images/landing_scan_qr.png',
  },
  {
    title: 'translation.landing_page_memberships_title',
    desc: 'translation.landing_page_memberships_desc',
    image: '/images/landing_memberships.png',
  },
] as const;

export default function InfoCard(): ReactElement {
  const swiperRef = useRef<SwiperRef>(null);
  const [slides, setSlides] = useState([
    ...appFeatures.map((slide) => (
      <CarouselItem title={slide.title} desc={slide.desc} image={slide.image} />
    )),
  ]);

  const pagination: PaginationOptions = {
    clickable: true,
    renderBullet: function (index, className) {
      return `<span class="${className} inline-flex w-6 h-6 rounded-full mx-2 bg-neutral-300 hover:cursor-pointer"></span>`;
    },
  };

  const handleResize = (ev: UIEvent) => {
    const e = ev.target as Window;

    if (e.innerWidth < 768 && slides.length === appFeatures.length) {
      slides.unshift(<DownloadAppCard />);
      setSlides([...slides]);
    } else if (
      e.innerWidth >= 768 &&
      slides.length === appFeatures.length + 1
    ) {
      slides.shift();
      setSlides([...slides]);
    }
  };

  useEffect(() => {
    if (window) {
      if (window.innerWidth < 768) {
        slides.unshift(<DownloadAppCard />);
        setSlides([...slides]);
      }
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (window) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [window]);

  return (
    <div className="flex flex-col md:flex-row h-full md:h-[initial] text-white font-Poppins max-w-full justify-center relative w-full">
      <div className="flex flex-col md:flex-row h-full md:h-[initial] justify-center relative shadow-xl">
        <div className="flex max-w-full h-full md:h-[initial] items-center md:max-w-[400px] bg-white">
          <Swiper
            ref={swiperRef}
            modules={[Pagination, Autoplay]}
            autoHeight
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            pagination={pagination}
            slidesPerView={1}
          >
            {slides.map((slide, i) => (
              <SwiperSlide key={i} className="min-h-[600px]">
                {slide}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="hidden md:contents">
          <DownloadAppCard />
        </div>
      </div>
    </div>
  );
}

function DownloadAppCard(): ReactElement {
  const { t, i18n } = useTranslation();

  function changeLanguage(languageCode: string) {
    i18n.changeLanguage(languageCode);
    localStorage.setItem('lang_code', languageCode);
  }

  const appStoreImageUrl = '/images/' + t('translation.app_store_img');
  const playStoreImageUrl = '/images/' + t('translation.play_store_img');

  return (
    <div className="flex relative min-h-[inherit] flex-col items-center justify-between gap-8 px-12 bg-accent w-full">
      <LanguageSwitch
        variant="dark"
        className="absolute top-4 right-4"
        initLangCode={t('translation.lang_code')}
        handleLanguageChange={changeLanguage}
      />
      <div className="flex flex-col items-center gap-12 pt-12">
        <img src="/images/logo.png" alt="Purple Perks Logo" />
        <p className="text-lg text-white max-w-[250px]">
          {t('translation.landing_page_intro')}
        </p>
      </div>
      <div className="hidden md:block">
        {window.location.host.startsWith('uat') ? (
          <img
            src="/images/landing-uat-qr.png"
            width="150px"
            alt="Purple Perks Clubs QR"
          />
        ) : (
          <img
            src="/images/landing-prod-qr.png"
            width="150px"
            alt="Purple Perks Clubs QR"
          />
        )}
      </div>
      <div className="flex flex-col gap-4 py-6">
        <div className="flex flex-col md:flex-row justify-center items-center gap-4">
          {!isAndroid && (
            <a href={iOSUrl}>
              <img
                className="max-w-[183px]"
                src={appStoreImageUrl}
                alt="App Store"
                width="100%"
              />
            </a>
          )}
          {!isIOS && (
            <a href={androidURL}>
              <img
                className="max-w-[183px]"
                src={playStoreImageUrl}
                alt="Google Play"
                width="100%"
              />
            </a>
          )}
        </div>
        <div className="footer-copyright text-center py-2">
          <a className="underline hover:text-blue-200" href={privacyPolicyURL}>
            Privacy Policy
          </a>
        </div>
        <div className="footer-copyright text-center py-2">
          © 2023 Copyright:{' '}
          <a
            className="underline hover:text-blue-200"
            href="https://ozbul.com/"
          >
            Ozbul Bilişim
          </a>
        </div>
      </div>
    </div>
  );
}

// the t() function requires the types to be constant expressions instead of 'string'
interface CarouselItemProps {
  title: (typeof appFeatures)[number]['title'];
  desc: (typeof appFeatures)[number]['desc'];
  image: string;
}

function CarouselItem({ title, desc, image }: CarouselItemProps): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6 p-4 max-w-full min-h-[inherit] justify-around md:max-w-[400px]">
      <div className="flex justify-center h-[264px] w-auto">
        <img src={image} className="object-contain" alt={t(title)} />
      </div>
      <div className="flex flex-col items-start gap-4">
        <p className="text-2xl font-semibold">{t(title)}</p>
        <p className="text-left text-lg">{t(desc)}</p>
      </div>
      <p className="text-center text-md md:hidden">
        {t('translation.dont_have_app')}
        <a
          className="text-accent hover:text-accent"
          href={isAndroid ? androidURL : iOSUrl}
        >
          <span className="block">{t('translation.download_now')}</span>
        </a>
      </p>
    </div>
  );
}
