import InfoCard from "../Components/InfoCard";

const Landing = () => {
    return (
        <div className="text-dark bg-[#FAF9F6] h-[100vh]" style={{ width: "100%", textAlign: "center" }}>
            <div className="flex justify-center h-full items-center w-full">
                <InfoCard />
            </div>
        </div>
    )
}

export default Landing
