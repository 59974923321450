import {Suspense,} from 'react';
import './App.css';
import {Dataform} from "./Components/Dataform";
import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import Landing from './Pages/Landing';
import Scan from './Pages/Scan';
import PrivacyPolicy from './Components/PrivacyPolicy';
import "swiper/css";

function App() {

    return (
        <div className="App">
            <header className="App-Header">
                <Router>
                    <Switch>
                        <Route path="/guest/promotions/:id/:langCode"
                               children={<Suspense fallback={<>Failed</>}><Dataform/></Suspense>}/>
                        <Route exact path={"/guest/privacypolicy"} component={PrivacyPolicy}/>
                        <Route path={"/scan-qr"} component={Scan}/>
                        <Route path={"/"} component={Landing}/>
                        <Redirect to={"/"}/>
                    </Switch>
                </Router>
            </header>
        </div>
    );
}

export default App;
