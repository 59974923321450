import { ReactElement } from "react";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { isIOS } from "react-device-detect";
import ContinueInBottomSheet from "../Components/ContinueInBottomSheet";

export default function Scan(): ReactElement {
    const history = useHistory();
    const storeLink = isIOS ? "https://apps.apple.com/tr/app/purple-perks-club/id1613341800" : "https://play.google.com/store/apps/details?id=com.qr_code_app";

    const onDismiss = () => {
        history.replace("/");
    };

    return (
        <div className="text-dark bg-[#FAF9F6] h-[100vh]" style={{ width: "100%", textAlign: "center" }}>
            <div className="flex justify-center h-full items-center w-full">
                <ClipLoader color="#00ffcc" />
                <ContinueInBottomSheet open={true} onDismiss={onDismiss} appLink={storeLink} />
            </div>
        </div>
    )
}
