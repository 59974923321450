import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const DownloadAppPrompt = ({ storeDynamicLink }: { storeDynamicLink: any }) => {
  const [isVisible, setIsVisible] = useState(true);
  const { t, i18n } = useTranslation();
  if (!isVisible) return null;

  return (
    <motion.div
      initial={{ y: -50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        background: '#FDFCFB',
        color: 'white',
        padding: '14px 8px',
        textAlign: 'center',
        borderBottom: '1px solid lightgrey',
        zIndex: 1000,
      }}
    >
      <div className="flex w-full" style={{ position: 'relative' }}>
        <div
          className="flex w-full justify-between items-center  "
          style={{ margin: 'auto' }}
        >
          <div className="flex flex-row gap-2">
            <img width="40px" src="/images/pl-logo.png" alt="" />
            <p style={{ fontSize: '14px' }} className="text-left">
              <strong>Purple Perks Club</strong>
              <br></br>
              {t('translation.download_app_msg2')}
            </p>
          </div>
          <div>
            <a
              href={storeDynamicLink.storeDynamicLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              <button className="OpenAppButton btn px-2.5 py-1 uppercase ">
                {t('translation.open_btn')}
              </button>
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default DownloadAppPrompt;
