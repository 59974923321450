import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from "./Trans/en/translation.json";
import tr from "./Trans/tr/translation.json";
import el from "./Trans/el/translation.json";

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

export const defaultNS = 'translations'
export const resources = {
  en : {
    translations: en
  },
  el : {
    translations: el
  },
  tr : {
    translations: tr
  }
  // tr,
  // el,
} as const

export default i18n
  .use(initReactI18next)
  .init({
    debug: false,
    lng: 'en',
    ns: ["translations"],
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    defaultNS,
    resources,
  });


// export default i18n;